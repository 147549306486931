import React from 'react'
import { useTranslation } from 'react-i18next'
import BarChartIcon from '../assets/svgs/bar-chart-icon.svg'
import BracesIcon from '../assets/svgs/braces-ellipsis-icon.svg'
import ActiveCallIcon from '../assets/svgs/call-active-icon.svg'
import StarIcon from '../assets/svgs/getting-started-star-icon.svg'
import JourneyIcon from '../assets/svgs/journey-icon.svg'
import RocketIcon from '../assets/svgs/rocket-icon.svg'
import DeviceIcon from '../assets/svgs/video-device-icon.svg'
import WidgetIcon from '../assets/svgs/widget-icon.svg'
import Link from '../components/Link'
import ResourceCardSection from '../components/ResourceCardSection'
import Seo from '../components/Seo'
import BasicLayout from '../layouts/BasicLayout'

const HomePage: React.FC = (): JSX.Element => {
  const { t } = useTranslation()

  const featureCallout = [
    {
      icon: <WidgetIcon width="26px" height="26px" />,
      mainText: t('home.personalize'),
      subText: t('home.customerJourney'),
    },
    {
      icon: <DeviceIcon width="23px" height="23px" />,
      mainText: t('home.buildYourOwn'),
      subText: t('home.featuresServices'),
    },
    {
      icon: <BracesIcon width="28px" height="28px" />,
      mainText: t('home.integrate'),
      subText: t('home.withYourSystems'),
    },
    {
      icon: <BarChartIcon width="25px" height="25px" />,
      mainText: t('home.trackImprove'),
      subText: t('home.kpis'),
    },
  ]

  const cardContent = [
    {
      icon: <StarIcon />,
      mainText: t('home.programmaticContactCenter'),
      subText: t('home.card1'),
      bottom: (
        <Link to={'/documentation/getting-started'}>{t('home.readMore')}</Link>
      ),
    },
    {
      icon: <JourneyIcon />,
      mainText: t('home.customerJourneyData'),
      subText: t('home.card2'),
      bottom: <span>{t('home.comingSoon')}</span>,
    },
    {
      icon: <RocketIcon />,
      mainText: t('home.ai'),
      subText: t('home.card3'),
      bottom: <span>{t('home.comingSoon')}</span>,
    },
    {
      icon: <ActiveCallIcon />,
      mainText: t('home.experienceManagement'),
      subText: t('home.card4'),
      bottom: <span>{t('home.comingSoon')}</span>,
    },
  ]

  return (
    <BasicLayout>
      <Seo />
      <article className="landing">
        <section className="landing-banner">
          <div className="landing-banner-slogan-container">
            <h1 className="landing-banner-slogan-container-main-slogan">
              {t('home.buildTheNextGenerationContactCenter')}
            </h1>
            <h2 className="landing-banner-slogan-container-sub-slogan">
              {t('home.browseThroughOurGuides')}
            </h2>
            <div className="landing-banner-button-container">
              <Link to="/my-apps" className="md-button landing-banner-button">
                <span>TEST CACHE{t('home.buildIntegration')}</span>
              </Link>
              <Link
                to="/documentation/getting-started"
                className="md-button landing-banner-button"
              >
                <span>{t('home.goToDocumentation')}</span>
              </Link>
            </div>
          </div>
          <div className="landing-banner-image-container"></div>
        </section>
        <section className="landing-banner-feature-callout">
          <div className="landing-banner-feature-callout-container">
            {featureCallout.map((feature, i) => {
              return (
                <div key={i} className="feature-callout">
                  <div className="gradient-border-left" />
                  <div className="content-wrapper">
                    <div className="icon-outer-gradient">
                      <div className="icon-wrapper">{feature.icon}</div>
                    </div>
                    <p>{`{ ${feature.mainText} }`}</p>
                    <p>{feature.subText}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        <section className="landing-body">
          <div className="landing-body-abstract-bg" />
          <div className="landing-body-card-section">
            <h1 className="landing-body-card-section-header">
              <span>
                {`{ `}
                <span className="header-text">
                  {t('home.contactCenterDeveloper')}
                </span>
                {` }`}
              </span>
            </h1>
            <p className="landing-body-card-section-header-sub">
              {t('home.cardSectionSubHeader')}
            </p>
            <div className="landing-body-card-container">
              {cardContent.map((card, i) => {
                return (
                  <div key={i} className="landing-body-card">
                    <div className="card-content-wrapper">
                      <div className="card-icon">{card.icon}</div>
                      <p className="card-main-text">{card.mainText}</p>
                      <p className="card-sub-text">{card.subText}</p>
                      <div className="card-bottom-text">{card.bottom}</div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </article>

      <section className="landing-body-resource-section">
        <h2 className="landing-body-resource-section-heading">
          {t('home.resources')}
        </h2>
        <div className="landing-body-resource-container">
          <ResourceCardSection />
        </div>
      </section>
    </BasicLayout>
  )
}

export default HomePage
