import React, { useEffect } from 'react'
import MetricsService from '../../../services/metricsService'
import { IResourceCard } from '../index'
import { Icon } from '@momentum-ui/react'
import Link from '../../Link'
import { useTranslation } from 'react-i18next'

const ResourceCard: React.FC<IResourceCard> = ({
  href,
  hrefText,
  label,
  body,
  img,
}) => {
  const metricsService = MetricsService.getInstance()
  const { t } = useTranslation()

  useEffect(() => {
    metricsService.trackExternalLinkVisited(`#${href}`, {
      name: label,
    })
  }, [href, label, metricsService])

  return (
    <article className="resource-card">
      {img}
      <h3 className="heading">{label}</h3>
      <p className="body">{body}</p>
      <Link
        to={href}
        target="_blank"
        rel="noopener noreferrer"
        className="default-link"
      >
        {hrefText} <Icon name="pop-out_12" />
      </Link>
    </article>
  )
}

export default ResourceCard
